import '../scss/app.scss';

// Your JS Code goes here
// import Hero from '../components/hero/hero.js'
import barba from '@barba/core';
import { gsap } from "gsap";

import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

let workScrollPosition = 0;


// let heroEl = document.querySelector(".hero");
// let hero = new Hero();
// hero.init(heroEl)



// barba.init({
// 	preventRunning: true,
// 	debug: true,
// 	logLevel: 'debug',
// 	transitions: [

// 	{ //last defined transition is the default:
// 		name: 'opacity-transition',
// 		leave(data) {
// 		  return gsap.to(data.current.container, {
// 			opacity: 0,
// 			duration: 0.5,
// 			y: 200,
// 			delay: 0
// 		  });
// 		},
// 		enter(data) {
// 		  return gsap.from(data.next.container, {
// 			opacity: 0,
// 			duration: 0.5,
// 			y: 200,
// 			delay: 0.5,
// 			onStart: entering(data)
// 		  });
// 		}
// 	  }
// 	]
// });


// function entering(data) {
//   let currentPage = data.next.namespace;
//   window.scrollTo(0, 0);
// }



import { resetLogoAnimation } from "./../components/logo/logo";
// import { initModalTeasers } from "./components/modal/modal";


// import "./components/navi/navi";
import "./../components/logo/logo.js";
// import "./components/switch/switch";
// import "./components/modal/modal";

// import "./../../static/switch.mp3";
// import "./../../static/switch-back.mp3";
// import "./../../static/neon-light3.mp3";


// initModalTeasers()

let logLevel = false;
if (window.location.hostname === 'localhost') {
    // console.log('You are on localhost.');
	logLevel = 'debug';
} else {
    // console.log('You are not on localhost.');
}


barba.init({
	preventRunning: true,
	debug: false,
	logLevel: logLevel,
	transitions: [
	{
	  name: 'fromHome',
	  from: { //this transition is only applied when navigating FROM home
			namespace: ['home']
	  },
	  leave(data) {
		workScrollPosition = 0;
		return gsap.to(data.current.container, {
		  opacity: 0.5,
		  duration: 0.5,
		  y: 200,
		  delay: 1.9 //this transition has a longer delay
		});
	  },
	  enter(data) {
		return gsap.from(data.next.container, {
		  opacity: 0,
		  duration: 0.5,
		  y: 200,
		  delay: 0.5,
		  onStart: entering(data)
		});
	  }
	},
	{
		name: 'toProject',
		to: { //this transition is only applied when navigating TO project
			  namespace: ['project']
		},
		enter(data) {
			data.current.container.style.position = 'absolute';
			workScrollPosition = window.scrollY;
			data.current.container.style.top = `-${workScrollPosition}px`;

		  gsap.from(data.next.container.querySelectorAll('.grid'), {
			duration: 0.75,
			y: 800,
			// stagger: 0.1,
			onStart: entering(data)
		  });
		  return gsap.from(data.next.container, {
			duration: 0.5,
			y: '100vh',
			delay: 0,
			onStart: entering(data)
		  });
		}
	  },
	{
		name: 'fromProject',
		from: { //this transition is only applied when navigating FROM project
			  namespace: ['project']
		},
		enter(data) {
			data.current.container.style.top = `${workScrollPosition}px`;
			data.current.container.style.zIndex = 200;
			return gsap.to(data.current.container, {
			  duration: 0.5,
			  y: '100vh',
			  delay: 0,
			  onStart: entering(data)
			});
		}
	  },
	{ //last defined transition is the default:
		name: 'opacity-transition',
		leave(data) {
		  return gsap.to(data.current.container, {
			opacity: 0,
			duration: 0.5,
			y: -200,
			delay: 0
		  });
		},
		enter(data) {
		  return gsap.from(data.next.container, {
			opacity: 0,
			duration: 0.5,
			y: 200,
			delay: 0.5,
			onStart: entering(data)
		  });
		}
	  }
	]
  });


  let switchEl = document.querySelector( '.wil-switch' );
  let closeEl = document.querySelector( '.wil-modal-close' );
  let navi = document.querySelector( '.wil-navi' );
  let logo = document.querySelector( '.wil-logo' );
  let contact = document.querySelector( '.wil-contact' );

  if(document.querySelector('.project')) {
	closeEl.classList.remove('is-hidden')
  }

  function entering(data) {
	  let currentPage = data.next.namespace;
	  if(data.next.namespace == 'work') {
		window.scrollTo(0, workScrollPosition);
	  } else {
		window.scrollTo(0, 0);
	  }
		// initModalTeasers()

		const bgWrapper = document.querySelector('.wil-background')

		if(currentPage == "home") {
			resetLogoAnimation()
			// navi.classList.add('is-hidden')
			contact.classList.add('is-hidden')
			switchEl.classList.remove('is-hidden')
			logo.classList.add('is-centered')
			logo.classList.remove('is-dark');

			bgWrapper.querySelectorAll('.wil-background__image').forEach( ( l ) => {
				l.classList.remove( 'active' );
			});
			bgWrapper.querySelector('.home').classList.add('active')
		}

		if(currentPage == "work") {
			logo.classList.remove('is-dark');
			contact.classList.remove('is-hidden')
			bgWrapper.querySelectorAll('.wil-background__image').forEach( ( l ) => {
				l.classList.remove( 'active' );
			});
			bgWrapper.querySelector('.work').classList.add('active')
		}

		closeEl.classList.add('is-hidden')
		if(data.next.container.querySelector('.project')) {
			logo.classList.add('is-dark');
			contact.classList.add('is-hidden')
			closeEl.classList.remove('is-hidden')
		}

		initConsentButtons(data.next.container);

		initSwiper(data.next.container)
  }



initConsentButtons(document);

initSwiper(document)

function initConsentButtons(container) {
    container.querySelectorAll('.button--vimeo-consent').forEach(btn => {
        btn.addEventListener('click', (e) => {
            setCookie('vimeoagreement', true, 31);
            window.location.reload(); // Reload the page after setting the cookie
        })
    })
}


function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function getCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for(var i=0;i < ca.length;i++) {
		var c = ca[i];
		while (c.charAt(0)==' ') c = c.substring(1,c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
	}
	return false;
}


function initSwiper(currentPage) {


	Swiper.use([Navigation, Pagination]);

	const slidewshows = currentPage.querySelectorAll('.wil-modal-slideshow .swiper')
	// console.log(slidewshows)
	if(slidewshows.length > 0) {
		slidewshows.forEach(el => {
			const swiper = new Swiper(el, {
				// Optional parameters
				direction: 'horizontal',
				loop: true,
				speed: 600,
				spaceBetween: 20,
		
				// If we need pagination
				pagination: {
				el: '.swiper-pagination',
				clickable: true,
				//dynamicBullets: true,
				},
		
				// Navigation arrows
				navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
				},
		
				// And if we need scrollbar
				scrollbar: {
				el: '.swiper-scrollbar',
				},
			});
		})
	}

}