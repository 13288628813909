import gsap from "gsap";
// import { updateNavi } from "./../navi/navi";

let logo = document.querySelector( '.wil-logo' );
let w = document.querySelector( '.wil-logo #w' );
let i = document.querySelector( '.wil-logo #i' );
let l = document.querySelector( '.wil-logo #l' );
let e = document.querySelector( '.wil-logo #e' );
let eBars = document.querySelectorAll( '.wil-logo .ebars' );
let y = document.querySelector( '.wil-logo #y' );
let x = document.querySelector( '.wil-logo #x' );

let switchEl = document.querySelector( '.wil-switch' );

let switchHandle = document.querySelector( '.wil-switch-inner' );

let navi = document.querySelector( '.wil-navi' );
let contact = document.querySelector( '.wil-contact' );

// import soundLightFile from './../../assets/neon-light3.mp3'
let soundLightFile = './static/neon-light3.mp3';
let soundLight = new Audio( soundLightFile );
soundLight.volume = 0.25;
soundLight.volume = 0.8;

function playSound() {
	soundLight.play();
}

let tl = gsap.timeline({
	paused: true, // default is false
	defaults: { // children inherit these defaults
	  duration: 0.2,
	  ease: "circ.inOut"
	},
	onStart: playSound,
	onComplete: navigatePage
});

function navigatePage() {
	currentPage = document.querySelector('.container').dataset.barbaNamespace
	// console.log(currentPage)
	if(currentPage == "home") {
		logo.classList.remove('is-centered')
		switchEl.classList.add('is-hidden')
		// updateNavi(document.querySelector('.wil-navi .commercial'));
		// contact.classList.remove('is-hidden')
		// navi.classList.remove('is-hidden')
	} else {
		// logo.classList.add('is-centered')
		// switchEl.classList.remove('is-hidden')
	}
}


tl.fromTo(w, {opacity: 0}, {opacity: 1, duration: 0.2, repeat: 2, repeatDelay: 0.2, yoyo: true}, 0);
tl.fromTo(i, {opacity: 0}, {opacity: 1, duration: 0.1, repeat: 2, repeatDelay: 0.2, yoyo: true}, 0);
tl.fromTo(l, {opacity: 0}, {opacity: 1, duration: 0.2}, 0.4);
tl.fromTo(e, {opacity: 0}, {opacity: 1, duration: 0.2, repeat: 2, repeatDelay: 0.2, yoyo: true}, 0);
tl.fromTo(y, {opacity: 0}, {opacity: 1, duration: 0.1, repeat: 2, repeatDelay: 0.2, yoyo: true}, 0.4);
tl.fromTo(x, {opacity: 0}, {opacity: 1, duration: 0.2}, 0);
tl.fromTo(eBars, {scaleX: 0.5}, {scaleX: 1, duration: 0.4});
tl.fromTo([y, x], {x: -20}, {x: 0, duration: 0.4}, "-=0.4");
tl.fromTo(x, {rotation: -45, transformOrigin: '50% 50%'}, {rotate: 0, duration: 0.4}, "-=0.4");
tl.fromTo(x, {y: 0}, {y: 0, duration: 0.5}); //instead of a break

//switch handle
tl.fromTo(switchHandle, {x: 0}, {x: 100, duration: 0.4}, 0);

const wilSwitch = document.querySelector('.wil-switch');
if(wilSwitch) {
	wilSwitch.addEventListener('click', (event) => {
		tl.play();
	})
}

let currentPage = document.querySelector('.container').dataset.barbaNamespace
if(currentPage != "home") {
	tl.play();
}

export function resetLogoAnimation() {
	tl.pause();
	tl.time(0);
}
